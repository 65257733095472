//import TweenMax from 'TweenMax';
import { TimelineMax } from 'gsap';
import fullpage from 'fullpage.js/dist/fullpage.extensions.min.js';
import 'polyfill-array-includes';

function ticker(el, value, endValue, increment, time) {
    el.innerHTML = value;
    let timeInterval = setInterval(function() {
        value = value + increment;
        value = Math.round( value * 10 ) / 10;
        el.innerHTML = value;
        if(value >= endValue) {
            clearInterval(timeInterval);
        }
    }, time);
}

// DOCUMENT READY
document.addEventListener("DOMContentLoaded", function() {
    const $siteHeader = document.getElementById('site-header');
    const $slides = document.querySelectorAll('.section');
    const $mainLogo = document.getElementById('main-logo');
    const headerHeight = $siteHeader.offsetHeight + 'px';

    new fullpage('#fullpage', {
        licenseKey: '90D975E6-9A704859-A510E0AA-2B84DC3B',
        navigationPosition: 'right',
        scrollOverflow: true,
        scrollOverflowReset: true,
        scrollingSpeed: 1400,
        controlArrows: false,
        slidesNavigation: true,
        anchors: ['slide1', 'slide2', 'slide3', 'slide4', 'slide5', 'slide6', 'slide7'],

        onLeave: function(origin) {
            const fadeIns = $slides[origin.index].querySelectorAll('.el-fade-in');
            const tl = new TimelineMax();
            fadeIns.forEach(function(el){
                tl.fromTo(el, 0.35, {opacity:1, y:0}, {opacity:0, y:20});
            });

            $mainLogo.classList.remove('active');
        },

        afterLoad: function(origin, destination) {
            $mainLogo.classList.add('active');

            // Logo and Nav color Changes
            if ([3].includes(destination.index)) {
                document.body.setAttribute('data-bgColor', null);
            } else if ([1,5].includes(destination.index)) {
                document.body.setAttribute('data-bgColor', 'dark-split');
            } else {
                document.body.setAttribute('data-bgColor', 'dark');
            }

            const fadeIns = $slides[destination.index].querySelectorAll('.el-fade-in');
            const tl = new TimelineMax();

            fadeIns.forEach(function(el){
                tl.fromTo(el, 0.35, {opacity:0, y:20}, {opacity:1, y:0});
            });


            if ( destination.index === 4 ) {
                console.log('ticker');
                const $ticker1 = document.querySelector('.ticker-1');
                const $num1 = $ticker1.querySelector('.num');
                const $ticker2 = document.querySelector('.ticker-2');
                const $num2 = $ticker2.querySelector('.num');
                ticker($num1, 0.1, 1.9, 0.1, 150);
                ticker($num2, 1, 31, 1, 100);

            }
        }
    });

    // SLIDE PADDING
    if (window.innerWidth > 659) {
        const $logoPaddingInner = document.querySelectorAll('.logo-padding-offset');
        $logoPaddingInner.forEach(function(el) {
            el.style.paddingTop = 178 + 'px';
            //el.querySelector('.content .inner').style.paddingTop = 178 + 'px';
        });
    }

    // Full Screen Video
    const $videoModal = document.getElementById('main-video-modal');
    const video = document.getElementById('main-video');
    const $videoButton = document.querySelector('.main-video-button');
    const $bgVideo = document.querySelector('.fullpage-bg-video');

    $videoButton.addEventListener('click', function(){
        $videoModal.classList.add('active');
        video.play();
        $bgVideo.pause();
    });

    $videoModal.addEventListener('click', function(event) {
        if(event.currentTarget !== event.target) {
            return;
        }
        $videoModal.classList.remove('active');
        video.pause();
        $bgVideo.play();
    });

});


function docReady(fn) {
    // see if DOM is already available
    if (document.readyState === "complete" || document.readyState === "interactive") {
        // call on next available tick
        setTimeout(fn, 1);
    } else {
        document.addEventListener("DOMContentLoaded", fn);
    }
}

docReady(function() {
    // DOM is loaded and ready for manipulation here
    const banner = document.querySelector('.mtsnb');

    if ( banner.classList.contains('mtsnb') ) {
        document.body.classList.add('banner-active');
    }
});
